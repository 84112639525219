







































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import {mapActions, mapGetters} from "vuex";
import vSelect from "vue-select";
import {required, email} from "vuelidate/lib/validators";
import { errorMessage } from '@/utils/messages-handlers';

export default Vue.extend({
  name: "submit-request-form" as string,

  components: {
    Layout,
    vSelect
  },

  async mounted() {
    await this.setSupportTicket();
    await this.setCompany(this.companyId);
    this.email = this.user.email
    this.businessName = this.company.name
    this.phoneNumber = this.user.phone_number ? this.user.phone_number : ''
  },

  data() {
    return {
      email: '',
      phoneNumber: '',
      businessName: '',
      subject: '',
      description: '',
      attachments: [],
      fakeAttachments: [],
      category:'',
      item: '',
      isSubmitted: false,
      payloadFiles: [],
      supportedFormats: ['gif', 'png', 'jpg', 'jpeg', 'rar', 'xlsx', 'xltx', 'xls', 'zip', 'pdf', 'txt', 'docx', 'docm', 'doc', 'dot', 'xml', 'csv', 'mp4', 'odp', 'wmv', 'tiff','mov']
    }
  },

  //@ts-ignore
  validations: {
    email: { required, email },
    phoneNumber: {
      checkPhoneNumber(phoneNumber) {
        if(phoneNumber === null || phoneNumber === '') return true;
          return (/^[+\d](?:.*\d|\s*)(?:.*\d|\s*)?$/.test(phoneNumber));
      }
    },
    subject: { required },
    description: { required },
    businessName: { required },
    category: { required },
  },

  computed: {
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
      company: "GET_COMPANY",
    }),
    ...mapGetters('userModule', {
        user: "GET_LOGGED_USER",
    }),
    ...mapGetters("helpAndSupportModule", {
      supportTicket: "GET_SUPPORT_TICKET_CATEGORY",
      loader: "GET_LOADER"
    }),
    formatFile(): string {
      return this.item.split(',')[1];
    },
    validateAttachmentSize(): boolean {
      const limit: number = 10000000; //10MB
      let size: number = 0;

      if (!this.fakeAttachments.length) return false;

      this.fakeAttachments.forEach((a) => {
        size += a.size;
      })

      return size > limit;
    }
  },

  methods: {
    ...mapActions("helpAndSupportModule", {
      setSupportTicket: "FETCH_SUPPORT_TICKET_CATEGORY",
      addTicket: "CREATE_SUPPORT_TICKET",
    }),
    ...mapActions("companyModule", {
      setCompany: "SET_COMPANY",
    }),
    previewFile(item, e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length){
        return;
      }
      this.createImage(item, files[0]);
    },
    createImage(item, file) {
      let image = new Image();
      let reader = new FileReader();

      reader.onload = (e) => {
        let lastIndex = file.name.lastIndexOf('.')
        let attachment =  file.name.substr(lastIndex).replace('.','').toLocaleLowerCase();

        if(!this.supportedFormats.includes(attachment)){
          this.attachments = [];
          return errorMessage('Unsupported file format',5000);
        }
        if(this.fakeAttachments.length >=3){
          return errorMessage("Attachment can have a maximum of 3 files.", 5000);
        }

        this.fakeAttachments.push(this.attachments);

        this.payloadFiles.push({
          attachment: (e.target.result as string).split(',')[1],
          attachment_extension: file.name.split('.')[1]
        });
        this.item = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async createTicket(): Promise<void> {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid){
       return this.$scrollTo(1)
      };

      if (this.validateAttachmentSize) {
        errorMessage("Attachment limit is 10MB.", 5000);
        return;
      }

      const payload: object = {
        subject: this.subject,
        description: this.description,
        email: this.email,
        phone: this.phoneNumber,
        business_name: this.businessName,
        category_id: this.category.value,
        venue_owner_id: this.companyId,
      };

      if (this.payloadFiles[0]){
        payload["attachment1"] = this.payloadFiles[0].attachment;
        payload["attachment1_extension"] = this.payloadFiles[0].attachment_extension;
      };
      if (this.payloadFiles[1]){
        payload["attachment2"] = this.payloadFiles[1].attachment;
        payload["attachment2_extension"] = this.payloadFiles[1].attachment_extension;
      };
      if (this.payloadFiles[2]){
        payload["attachment3"] = this.payloadFiles[2].attachment;
        payload["attachment3_extension"] = this.payloadFiles[2].attachment_extension;
      };
      await this.addTicket(payload);

      this.subject = "";
      this.description = "";
      this.email = this.user.email
      this.businessName = this.company.name
      this.phoneNumber = this.user.phone_number ? this.user.phone_number : ''
      this.category = "";
      this.item = null;
      this.attachments = [];
      this.fakeAttachments = [];
      this.payloadFiles = [];
      this.isSubmitted = false;

      this.$router.push("/support");
    },
    removeFile(index: number): void {
      this.payloadFiles.splice(index,1)
      this.fakeAttachments.splice(index,1)
      this.attachments = [];

    },
  },

  watch: {
    company(value) {
      this.businessName = value.name
    },
    user(value) {
      this.email = value.email;
      this.phoneNumber = value.phone_number ? value.phone_number : ''
    },
  }
});
